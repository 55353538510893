<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "ApiKeysManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("apikeys", {
      contents: state => state.api_keys
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userScope: state => state.userType,
      userRole: state => state.userRole
    }),
    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider"
    }),
    ...mapActions("users", {
      getSingleUser: "getSingleUser"
    }),
    ...mapActions("apikeys", {
      setSystemAccess: "setSystemAccess"
    }),
    ...mapActions("accounts", {
      getAccountsForServiceProvider: "getAccountsForServiceProvider"
    }),
    initContent() {
      return {
        is_enabled: true,
        api_key: "",
        api_key_name: "",
        user_id: "",
        ac_id: "",
        user_permissions: []
      };
    },
    async refreshScopeTemplate() {
      if (
        this.userRole === "system" &&
        (this.selectedAccountId !== "all" ||
          this.selectedServiceProviderId !== "all")
      ) {
        // for sys scoped users hide if all-sp option is not selected
        this.forceShowNotScoped = true;
        this.forceScopeName = "Service Provider";
      } else if (this.userScope === "sp" && this.selectedAccountId !== "all") {
        // for sp scoped users hide if all-accounts is not selected
        this.forceShowNotScoped = true;
        this.forceScopeName = "Account";
      } else {
        // show the api-key create page
        this.forceShowNotScoped = false;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
      vm.setSystemAccess(false);
    });
  },
  // async mounted() {},
  watch: {
    selectedAccountId: {
      immediate: true,
      handler: "refreshScopeTemplate"
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: "refreshScopeTemplate"
    },
    selectedServiceProviderAccountCombination: {
      immediate: true,
      async handler() {
        this.contentType = "API Key";
        this.primaryKey = "user_api_key_id";
        if (this.userScope === "ac") {
          this.scope = "ac";
        } else if (this.userRole === "system") {
          this.scope = "sys";
        }
        await this.refreshScopeTemplate();
        // fetch user and account info only when the create or edit page is shown
        if (!this.forceShowNotScoped) {
          this.getAccountsForServiceProvider({ fetch_all: 1 });
          let user_id = this.$auth.user().user_id;
          await this.getSingleUser(user_id);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
